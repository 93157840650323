@import '~bootstrap/scss/bootstrap.scss';

:root {
	--plenoil-blue: #002f73;
	--plenoil-blue-light: #879cbd;
	--plenoil-blue-superlight: #e1ecfd;
	--plenoil-orange: #ff7a00;
	--plenoil-yellow: #fcd937;
	--plenoil-brown-light: #dab89d;
	--plenoil-brown-light2: #f1e8df;
	--plenoil-black: #231f20;
	--plenoil-gray: #575756;
	--plenoil-green: #007961;
	--plenoil-white: white;
	--plenoil-red: #d23627;
	--plenoil-purple: #774F8D;

	--plenoil-background: #fff2e7;
	--plenoil-background-gray: #f4f4f4;

	--plenoil-adblue: #1567b8;
}

html, body {
	font-family: Dax;
}

.text-plenoil-primary {
	color: var(--plenoil-blue) !important;
}

.text-plenoil-red {
	color: var(--plenoil-red) !important;
}

.text-plenoil-orange {
	color: var(--plenoil-orange) !important;
}

.text-plenoil-yellow {
	color: var(--plenoil-yellow) !important;
}

.text-plenoil-brown-light {
	color: var(--plenoil-brown-light) !important;
}

.text-plenoil-brown-light2 {
	color: var(--plenoil-brown-light2) !important;
}

.text-plenoil-black {
	color: var(--plenoil-black) !important;
}

.bg-plenoil-primary {
	background: var(--plenoil-blue) !important;
}

.bg-plenoil-brown-light {
	background: var(--plenoil-brown-light) !important;
}

.bg-plenoil-brown-light2 {
	background: var(--plenoil-brown-light2) !important;
}

.bg-plenoil-red {
	background: var(--plenoil-red);
}

.bg-plenoil-green {
	background: var(--plenoil-green);
}

.bg-plenoil-black {
	background: var(--plenoil-black);
}

.bg-plenoil-orange {
	background: var(--plenoil-orange);
}

.btn.btn-plenoil-primary {
	background: var(--plenoil-blue);
	color: var(--plenoil-white);

	&:hover {
		transform: scale(1.01);
		color: var(--plenoil-white) !important;
		background-color: var(--plenoil-blue) !important;
	}
}

.btn-plenoil-primary:hover {
	color: var(--plenoil-white);
}

.btn-plenoil-blue-light {
	background: var(--plenoil-blue-light);
	color: white;
}

.btn-plenoil-blue-light:hover {
	background: var(--plenoil-blue-superlight);
}

.btn-plenoil-orange {
	background: var(--plenoil-orange);
	color: var(--plenoil-white);
	border-radius: 30px;
	padding: 5px 20px;
}

.bg-plenoil-purple {
	background: var(--plenoil-purple);
}

.text-plenoil-purple {
	background: var(--plenoil-purple);
}

table {
	tbody {
		border-top-color: var(--plenoil-blue) !important;
	}
}

table.table-custom {
	thead {
		tr {
			// background: var(--bs-gray-100);
			font-size: 13px;
			font-weight: normal;

			th {
				&.active {
					color: var(--plenoil-blue);
				}
			}
		}
	}

	& > tbody {
		& > tr {
			&:not(.details):hover {
				background: rgba(251, 215, 36, 0.2);
			}

			&.details {
				border-bottom: 2px solid var(--plenoil-blue);

				&:hover {
					background: inherit !important;

					* {
						--bs-table-accent-bg: inherit !important; // Prevent hover background for tablehover if tr is details container
					}
				}
			}

			&.has-details {
				cursor: pointer;

				&.opened {
					border-top: 2px solid var(--plenoil-blue);
				}
			}

			td {
				font-size: 14px;
				font-weight: normal;
			}
		}
	}
}

.form-check-input:checked {
	background-color: var(--plenoil-blue);
	border-color: var(--plenoil-blue);
}

input[type=number].no-arrows {
	/* Chrome, Safari, Edge, Opera */
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
	  -webkit-appearance: none;
	  margin: 0;
	}

	/* Firefox */
	& {
	  -moz-appearance: textfield;
	}
}




//--------------------------------------------------------------------------------------//
//                                       Parpadeo                                       //
//--------------------------------------------------------------------------------------//

.parpadeo {
	animation-name: parpadeo;
	animation-duration: 1s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
  
	-webkit-animation-name:parpadeo;
	-webkit-animation-duration: 1s;
	-webkit-animation-timing-function: linear;
	-webkit-animation-iteration-count: infinite;
  }
  
  @-moz-keyframes parpadeo{  
	0% { opacity: 1.0; }
	50% { opacity: 0.0; }
	100% { opacity: 1.0; }
  }
  
  @-webkit-keyframes parpadeo {  
	0% { opacity: 1.0; }
	50% { opacity: 0.0; }
	 100% { opacity: 1.0; }
  }
  
  @keyframes parpadeo {  
	0% { opacity: 1.0; }
	 50% { opacity: 0.0; }
	100% { opacity: 1.0; }
  }